export const publicDatapointFlow = {
  order: 2,
  name: "Understand how datapoints become public",
  subcategory: "Make database changes",
  markdown: `# Understand how datapoints become public

  Tower Hunt's public data layer is a powerful resource for users, giving them a head start on the often difficult job of tracking market activity. But how do {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} become public? Read on to find out.

  {% callout type="tip" %}
  **Tip:** New to Tower Hunt? Take a look at our introduction to {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoints to learn about the differences between public and private data.
  {% /callout %}

  ## What to expect

  In Tower Hunt, the path to becoming a public datapoint is called *the validation pipeline*. There are two ways for a datapoint to enter the pipeline:

  - A {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoint is declassified
  - A {% inlineRouterLink articleId="default-public-data-types" %}default-public{% /inlineRouterLink %} datapoint is {% inlineRouterLink articleId="create-a-datapoint" %}created{% /inlineRouterLink %}

  Once in the pipeline, here are the stages a datapoint goes through:

  {% validationPipeline /%}

  ## Stake

  In Tower Hunt, it takes money to make money. When you place a datapoint in the validation pipeline, you {% inlineRouterLink articleId="what-is-staking?" %}stake{% /inlineRouterLink %} credits. This is conceptually similar to the ante in a game of poker or the collateral for a loan, but on a much smaller scale. The datapoint passes through this stage once credits are staked.

  {% callout type="tip" %}
  **Tip:** For active contributors, staking is a big part of the upside that Tower Hunt offers. Read more about {% inlineRouterLink articleId="what-is-staking?" %}what staking is{% /inlineRouterLink %} and how it is affected by {% inlineRouterLink articleId="understand-reputation" %}your reputation{% /inlineRouterLink %}.
  {% /callout %}

  If your available balance hits zero or goes negative and you are not subscribed to Tower Hunt, datapoints that you place in the validation pipeline will be *unstaked* and appear as {% inlineDatapoint state="unstaked" /%}. {% inlineRouterLink articleId="subscribe-to-tower-hunt" %}Subscribe{% /inlineRouterLink %} and/or {% inlineRouterLink articleId="add-a-payment-method" %}add a payment method{% /inlineRouterLink %} to get these datapoints staked and on to the next stage.

  ## Publish

  Once they are staked, datapoints must be published before validators are invited to vote on them. This stage provides you with an opportunity to review and iterate on datapoints before they are put to a vote. This is particularly useful when you are contributing to {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagrams{% /inlineRouterLink %}. Datapoints that can be published appear as {% inlineDatapoint state="unpublished" /%}. Publishing can be done from the {% inlineRouterLink articleId="datapoint-interactions" %}action menu{% /inlineRouterLink %} or the {% inlineRouterLink articleId="pending-contributions" %}pending contributions tab{% /inlineRouterLink %}.

  ## Validate

  Published datapoints become accessible to {% inlineRouterLink articleId="understand-validation" %}validators{% /inlineRouterLink %}. Datapoints that are being validated appear as {% inlineDatapoint state="inValidation" /%}. Once enough votes are cast, an {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}outcome is determined{% /inlineRouterLink %}. Datapoints that are accepted proceed to the next stage. Datapoints that are rejected only remain visible to their authors (and any sharing recipients).

  {% callout type="tip" %}
  **Tip:** For active contributors, validating is a big part of the upside that Tower Hunt offers. Read more about {% inlineRouterLink articleId="understand-validation" %}the details of the validation process{% /inlineRouterLink %} and how it is affected by {% inlineRouterLink articleId="understand-reputation" %}your reputation{% /inlineRouterLink %} and {% inlineRouterLink articleId="link-your-account-to-a-contact" %}whether your account is linked to a contact{% /inlineRouterLink %}.
  {% /callout %}

  ## Deploy

  Accepted datapoints can now be seen by anyone on Tower Hunt, appearing as {% inlineDatapoint state="public" /%}. Depending upon the data type and context, users may receive a {% inlineRouterLink articleId="understanding-the-newsfeed" %}newsfeed notification{% /inlineRouterLink %} about the datapoint.`,
};
